// import Vue from 'vue'
// import VueRouter from 'vue-router'
import store from '../store/index'
import { createRouter, createWebHistory } from 'vue-router'
// import AdminPage from '../components/Admin/AdminPage'
// import UserLevels from '../components/Admin/Niveis'
// import Grupo from '../components/Admin/Grupo'
// import UserResources from '../components/Admin/Recursos'
// import UserPermission from '../components/Admin/Permissoes'
// import User from '../components/Admin/Usuarios'
// import Logout from '../views/Logout'
// import Login from '../views/Login.vue'
// import Home from '../views/Home'
// import Request from '../views/Request'
// import RouteCreator from '../views/RoutesCreator'
// import Clientes from '../views/Clientes'
// import Dispositivos from '../views/Dispositivos'
// import NotFound from '../views/NotFound'

// Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ "../views/Clientes.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    },
    {
        path: '/adm',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "Admin" */ "../components/Admin/AdminPage.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/userlevels',
        name: 'UserLevels',
        component: () =>
            import(/* webpackChunkName: "UserLevels" */ "../components/Admin/Niveis.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/userresources',
        name: 'UserResources',
        component: () =>
            import(/* webpackChunkName: "UserResources" */ "../components/Admin/Recursos.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/userpermission',
        name: 'UserPermission',
        component: () =>
            import(/* webpackChunkName: "UserPermission" */ "../components/Admin/Permissoes.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/grupo',
        name: 'Grupo',
        component: () =>
            import(/* webpackChunkName: "Grupo" */ "../components/Admin/Grupo.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/request',
        name: 'Request',
        component: () =>
            import(/* webpackChunkName: "Request" */ "../views/Request.vue"),
        // meta: { requiresAuth: true }
    },
    {
        path: '/user',
        name: 'User',
        component: () =>
            import(/* webpackChunkName: "User" */ "../components/Admin/Usuarios.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () =>
            import(/* webpackChunkName: "Logout" */ "../views/Logout.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/routecreator',
        name: 'RouteCreator',
        component: () =>
            import(/* webpackChunkName: "RouteCreator" */ "../views/RoutesCreator.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/clientes',
        name: 'Clientes',
        component: () =>
            import(/* webpackChunkName: "Clientes" */ "../views/Clientes.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/clientes/cadastro/:id',
        name: 'EditarCliente',
        component: () =>
            import(/* webpackChunkName: "Clientes" */ "../views/CadCliente.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/clientes/cadastro/',
        name: 'CadastroCliente',
        component: () =>
            import(/* webpackChunkName: "Clientes" */ "../views/CadCliente.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/cliente/:id',
        name: 'ClienteDashboard',
        component: () =>
            import(/* webpackChunkName: "ClientesDashboard" */ "../views/ClienteDashboard.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/dispositivos/:hash',
        name: 'Dispositivos',
        component: () =>
            import(/* webpackChunkName: "Dispositivos" */ "../views/Dispositivos.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: '/moradores/:hash',
        name: 'Moradores',
        component: () =>
            import(/* webpackChunkName: "Moradores" */ "../views/Moradores.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: '/convidados/:hash',
        name: 'Convidados',
        component: () =>
            import(/* webpackChunkName: "Convidados" */ "../views/Convidados.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: '/efetivos/:hash',
        name: 'Efetivos',
        component: () =>
            import(/* webpackChunkName: "Efetivos" */ "../views/Efetivos.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: '/usuario',
        name: 'Editar Usuario',
        component: () =>
            import(/* webpackChunkName: "EditarUsuario" */ "../views/editUsuario.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/foto',
        name: 'Foto',
        component: () =>
            import(/* webpackChunkName: "Foto" */ "../views/Foto.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: '/confirmacao/:hash',
        name: 'Confirmação de Cadastro',
        component: () =>
            import(/* webpackChunkName: "Confirmação de cadastro" */ "../views/ConfirmCadastro.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () =>
            import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: '/:pathMatch(.*)',
        name: 'BadNotFound',
        component: () =>
            import(/* webpackChunkName: "BadNotFound" */ "../views/NotFound.vue"),
        meta: { requiresAuth: false }
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    let logged = store.getters.getLogged
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // user && user.admin ? next() : next({ path: '/' })
        // logged ? next() : next({ path: '/login' })
        if (logged) {
            store.dispatch('setShowMenu', true);
            next()
        } else {
            next({ path: '/login' })
        }
    } else {
        next()
    }

})

export default router